body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media print {
  body {
    position: absolute;
    top: 0px;
    left: 0px;
  }
}
